
import PaisService from "../../../Services/PaisService";
import ClienteService from "../../../Services/ClienteService";
import MenuTelefonoLada from "@/views/leads/components/MenuTelefonoLada.vue";
import FotoThumbnailContenedorGlobal from "@/components/globales/FotoThumbnailContenedorGlobal.vue";
import ModalSubirFotoGlobal from "@/components/modales/ModalSubirFotoGlobal.vue";
export default {
  components: {
    MenuTelefonoLada,
    FotoThumbnailContenedorGlobal,
    ModalSubirFotoGlobal,
  },
  name: "datos-generales-detalle",
  data() {
    return {
      clienteObj: {},
      clienteOriginalObj: {},
      paisOpciones: [],
      estadoOriginalOpciones: [],
      estadoOpciones: [],
      zonaHorariaOpciones: [],
      ladas: this.constantesLadas.Ladas,
      fotoThumbnail: "",
      modalActualizarFoto: false,
    };
  },
  computed: {
    clienteEditado() {
      return (
        JSON.stringify(this.clienteObj) ==
        JSON.stringify(this.clienteOriginalObj)
      );
    },
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.obtenerDetalle(false);
      await this.obtenerConcentradoOpciones(false);
      this.loader(false);
    },
    async obtenerConcentradoOpciones(loader = true) {
      if (loader) this.loader(true);
      let filtros = {
        paisId: this.clienteObj.pais_id,
      };
      await ClienteService.obtenerConcentradoOpciones(filtros)
        .then((resp) => {
          this.paisOpciones = resp.paises;
          this.estadoOriginalOpciones = _.cloneDeep(resp.estados);
          this.estadoOpciones = _.cloneDeep(resp.estados);
          this.zonaHorariaOpciones = resp.zonasHorarias;
          if (loader) this.loader(false);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async obtenerDetalle(loader = true) {
      if (loader) this.loader(true);
      await ClienteService.obtenerDetalle({})
        .then((resp) => {
          this.clienteObj = _.cloneDeep(resp.info);
          this.clienteObj.ladaIcono = this.ladas.find((l) =>
            l.callingCodes.includes(
              this.clienteObj.lada_internacional.substring(1)
            )
          ).flag;
          this.clienteOriginalObj = _.cloneDeep(this.clienteObj);
          if (loader) this.loader(false);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async editar(loader = true) {
      // Almacenamos el pais original temporalmente
      let paisId = this.clienteOriginalObj.pais_id;

      this.clienteObj.direccion2 = this.clienteObj.direccion2 || "";
      this.clienteObj.sitio_web = this.clienteObj.sitio_web || "";
      if (loader) this.loader(true);
      await ClienteService.editar(this.clienteObj)
        .then(async (resp) => {
          await this.obtenerDetalle(false);
          if (paisId != this.clienteObj.pais_id)
            this.estadoOriginalOpciones = _.cloneDeep(this.estadoOpciones);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    cancelar() {
      this.clienteObj = _.cloneDeep(this.clienteOriginalObj);
      this.clienteObj.ladaIcono = this.ladas.find((l) =>
        l.callingCodes.includes(this.clienteObj.lada_internacional.substring(1))
      ).flag;
      this.estadoOpciones = _.cloneDeep(this.estadoOriginalOpciones);
    },
    async actualizarFoto(archivo) {
      this.loader(true);
      let datos = {
        archivo: archivo.imagen,
      };
      await ClienteService.actualizarFoto(datos)
        .then(async (resp) => {
          this.cerrarModalActualizarFoto();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    regresarPrimerElemento() {
      document.querySelector('[tabindex="1"]').focus();
    },
    regresarUltimoElemento() {
      document.querySelector('[tabindex="13"]').focus();
    },
    manejarTabUltimoElemento() {
      JSON.stringify(this.clienteObj) == JSON.stringify(this.clienteOriginalObj)
        ? this.regresarPrimerElemento()
        : this.$refs.btnCancelar.$el.focus();
    },
    async obtenerEstados(paisId, loader = true) {
      this.clienteObj.estado_id = null;
      let filtros = { paisId: paisId };
      if (loader) this.loader(true);
      await PaisService.listarEstados(filtros)
        .then((resp) => {
          this.estadoOpciones = resp;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    seleccionarLada(lada) {
      this.clienteObj.ladaIcono = lada.flag;
      this.clienteObj.lada_internacional = "+" + lada.callingCodes[0];
    },
    abrirModalActualizarFoto() {
      this.modalActualizarFoto = true;
    },
    cerrarModalActualizarFoto() {
      this.modalActualizarFoto = false;
    },
  },
};
